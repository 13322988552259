import { Container } from 'react-bootstrap';
import {  buddha } from '../../assets/images';
import './home.scss';

const Home = () => {

    return(
        <Container fluid className="home p-0 " id="home">
            <div className="left-panel">
                <div className="heading">
                    <h1>
                    Ayu Rashmi: offering hope and healing
                    </h1>
                    <h5>
                    Your Health is Our Top Priority
                    </h5>
                    </div>
                    <p>
                    Ayu Rashmi provides you a holistic ayurveda treatment that Heals your body,and mind naturally. 
                    As ayurveda is a holistic science that acknowledges the mind body correlation.समदोषः समाग्निश्च समधातु मल कियः | प्रसन्नात्मनेदिय मनः स्वस्थ्य इत्यभिघीयते ||We are specialized in ayurvedic
                     health services, and treats all patients with the utmost respect and care. We offer complementary and alternative healthcare to support 
                     your healing path to wellness. Based on your physical and psychological conditions, we evaluate the best treatment options available for you. 
                     Let us know how we can help you and we'll make sure you receive the best treatment. 
                    </p>
            </div>
            <div className="right-panel">
                <div className="image-wrapper">
                <img src={buddha} alt="banner" />
                </div>
            </div>
        </Container>
    )
}

export default Home