import { Container } from "react-bootstrap"

const PrivacyPolicy = () => {

    return(
        <Container fluid className="privacy-policy">
<h2 style={{textAlign: 'center'}}><b>PRIVACY POLICY</b></h2>
<p>Effective date: 2022-01-06</p>
<p>1. <b>Introduction</b></p>
<p>Welcome to <b> AyuRashmi</b>.</p>
<p><b>AyuRashmi</b> (“us”, “we”, or “our”) operates <b>https://ayu-rashmi.com</b> (hereinafter referred to as <b>“Service”</b>).</p>
<p>Our Privacy Policy governs your visit to <b>https://ayu-rashmi.com</b>, and explains how we collect, safeguard and disclose information that results from your use of our Service.</p>
<p>We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
<p>Our Terms and Conditions (<b>“Terms”</b>) govern all use of our Service and together with the Privacy Policy constitutes your agreement with us (<b>“agreement”</b>).</p>
<p>2. <b>Definitions</b></p>
<p><b>SERVICE</b> means the https://ayu-rashmi.com website operated by AyuRashmi.</p>
<p>3. <b>Types of Data Collected</b></p>
<p><b>Personal Data</b></p>
<p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (<b>“Personal Data”</b>).</p>
<p>0.1. Email address</p>
<p>0.2. First name and last name</p>
<p>0.3. Phone number</p>

<p>4. <b>Changes to This Privacy Policy</b></p>
<p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
<p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.</p>
<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
<p>5. <b>Contact Us</b></p>
<p>If you have any questions about this Privacy Policy, please contact us by email: <b>16ayurashmi@gmail.com</b>.</p>
<p style={{marginTop: '5em', fontSize: '0.7em'}}>This <b>Privacy Policy</b> was created for <b>https://ayu-rashmi.com</b> on 2022-01-06.</p>

        </Container>
    )
}
export default PrivacyPolicy;