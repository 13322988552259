import { Col, Container, Row} from 'react-bootstrap';
// import handleEmail, { sendMail } from '../../send-email';

  import 'react-toastify/dist/ReactToastify.css';
import { insta, mailIcon, mapIcon, mobileIcon } from '../../assets/images';
import './contact.scss';


const Contact = () => {
    // const options = {
    //     autoClose: 6000,
    //     hideProgressBar: false,
    //     position: toast.POSITION.TOP_RIGHT,
    //     pauseOnHover: true,
    // };
    // toast.configure({
    //     autoClose: 6000,
    //     draggable: false,
    //   })
    //   const initalFormData = {
    //     name: '',
    //     email: '',
    //     phone: '',
    //     description: ''
    // }
    // const [contactForm, setContactForm] = useState(initalFormData)
    // const [contactFormValidations, setContactFormValidations] = useState({
    //     isSubmit: false,
    //    validations: {}
    // });

    // const submitData = (e) => {
    //     console.log('e ===> ',e)
    //     e.preventDefault();
    //     console.log('contactForm ==> ', contactForm);
    //     setContactFormValidations({...contactFormValidations, isSubmit: true});
    //     fetch('https://ayu-rashmi-node.herokuapp.com/mail', {
    //         method: 'post',
    //         headers: {'Content-Type':'application/json'},
    //         body: JSON.stringify(contactForm)
            
    //        }).then(response => response.json()).then(success => {
    //             setContactForm(initalFormData)
    //             setContactFormValidations({...contactFormValidations, isSubmit: false});
    //            toast.success(success.message || "Mail Send Successfully", options);
    //        }).catch(error => {
    //            console.log('error sending mail => ', error);
    //            setContactFormValidations({...contactFormValidations, isSubmit: false});
    //            toast.error(error.message || "Something wend Wrong. Please try again later!", options);
    //        });
    // }

    // const formChangeHandler = (event) => {
    //     const { value, name } = event.target;
    //     const validations = Object.assign({}, contactFormValidations.validations)
    //     if(name === 'name') {
    //        const validName =  String(value)
    //         .toLowerCase()
    //         .match(/^[a-zA-Z ]*$/);
    //         validations[name] = validName ? false : true;
    //     } else if(name === 'email') {
    //         const validEmail =  String(value)
    //         .toLowerCase()
    //         .match(
    //         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //         );
    //         validations[name] = validEmail ? false : true;
    //     } else if(name === 'phone') {
    //         const validPhone =  String(value)
    //         .toLowerCase()
    //         .match(/^[0-9]*$/);
    //         validations[name] = validPhone ? false : true;
    //     } else if(name === 'description') {
    //         const validDescription =  String(value)
    //         .toLowerCase()
    //         .match(/^[a-zA-Z ]*$/);
    //         validations[name] = validDescription ? false : true;
    //     }
    //     setContactFormValidations({...contactFormValidations, validations})
    //     setContactForm({...contactForm, [name]: value})
    // }

    // const isDisabled = () => {
    //         const contactFormCopy = Object.assign({}, contactForm);
    //         const validationsCopy = Object.assign({}, contactFormValidations);
    //         const isValidForm = Object.keys(validationsCopy.validations).some(k => validationsCopy.validations[k]);
    //         return ((contactFormCopy.name.replace(/\s/g, "") === "") || (contactFormCopy.email.replace(/\s/g, "") === "") || (contactFormCopy.description.replace(/\s/g, "") === "") || isValidForm || contactFormValidations.isSubmit)
    // }

    const openPageHandler = (url) => {
        // console.log('url', url)
        window.open(url, '_blank');

    }

    return(
        <Container fluid className="contact" id="contact">
           <Row className="header my-5">
                <h5>Contact Us</h5>
                <h6>Get in touch with Dr. Rashmi Bhatt to learn more about our treatments and services.</h6>
            </Row>
            <Row>
                {/* <Col xs={12} sm={6} className="contact-form">
            <Form onSubmit={(e) => submitData(e)}>
            <Row className="my-2">
                <h5>Send us a message</h5>
            </Row>
                <Row>
                    <Col xs={12} sm={6}>
                    <Form.Group as={Col} controlId="formGridName">
                    <Form.Label>Name*</Form.Label>
                    <Form.Control placeholder="Name" maxLength={30} name="name" value={contactForm.name} onChange={(e) => formChangeHandler(e)}/>
                    { contactFormValidations.validations.name && <p className='error-msg'>Please enter a valid Name. Can Contains only characters. </p>}
                    </Form.Group>
                    
                    </Col>
                    <Col xs={12} sm={6}>
                    <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control placeholder="Email" name="email" type="email" value={contactForm.email} onChange={(e) => formChangeHandler(e)}/>
                    { contactFormValidations.validations.email && <p className='error-msg'>Please enter a valid Email ID </p>}
                    </Form.Group>
                    </Col>
                    <Col xs={12} sm={12}>
                    <Form.Group as={Col} controlId="formGridPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control placeholder="Phone" maxLength={10} name="phone" value={contactForm.phone} onChange={(e) => formChangeHandler(e)}/>
                    { contactFormValidations.validations.phone && <p className='error-msg'>Please enter a valid Phone number. Can Contains only numbers. </p>}
                    </Form.Group>
                    </Col>

                    <Col xs={12} sm={12}>
                    <Form.Group as={Col} controlId="formGridDescription">
                    <Form.Label>Description*</Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder="Type your message here"
                        style={{ height: '100px' }}
                        name="description"
                        maxLength={250}
                        value={contactForm.description}
                        onChange={(e) => formChangeHandler(e)}
                    />
                    { contactFormValidations.validations.description && <p className='error-msg'>Please enter a valid description. Can Contains only characters. </p>}
                    </Form.Group>
                    </Col>
                    
                </Row>
                <Row>
                    <Col xs={12} sm={4}>
                        <Button variant="primary" size="sm" type="submit" className="my-3" disabled={isDisabled()}>Submit</Button>{' '}
                    </Col>
                </Row>
            </Form>
            </Col> */}

           
            <Col xs={12} sm={12} className='map-iframe mt-2'>
            <iframe title='clinic address' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2642.3584912865194!2d78.15306817982763!3d30.186850269628053!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3909257b5a67f4e9%3A0x9124fddd1f4a3bf5!2sAyuRashmi%20Ayurveda%20Clinic!5e0!3m2!1sen!2sin!4v1691668462199!5m2!1sen!2sin" 
            width="600" 
            height="450" 
            allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </Col>
            <Col xs={12} sm={12} className='contact-information'>
                <Row>
                    <Col xs={6} className='d-flex align-items-center my-2'>
                        <img src={mapIcon} alt="Clinic Address" className='cursor-pointer' onClick={openPageHandler.bind(this, 'https://goo.gl/maps/NgJBZBE3DjFj2VX39')}/>
                        <p className='m-0 cursor-pointer' onClick={openPageHandler.bind(this, 'https://goo.gl/maps/NgJBZBE3DjFj2VX39')}>Viraj complex, Durga chowk, near shiva mandir, Jollygrant, Dehradun, Bhania Wala, Uttarakhand 248140</p>
                    </Col>
                    <Col xs={6} className='d-flex flex-column my-2'>
                        <div className='mobile d-flex align-items-center my-1'>
                            <img src={mobileIcon} alt="Contact Doctor"/>
                            <p className='m-0'>
                                +91 9634088891
                            </p>
                        </div>
                        <div className='email d-flex align-items-center my-1'>
                            <img src={mailIcon} alt="Email Doctor"/>
                            <p className='m-0'>
                                rashmi.bhatt19@gmail.com
                            </p>
                        </div>
                        <div className='social d-flex align-items-center my-1'>
                            <img src={insta} className='cursor-pointer' alt='ayurrashmi insta ' 
                            onClick={openPageHandler.bind(this, 'https://instagram.com/16ayurashmi?igshid=MzRlODBiNWFlZA==')}/>
                            <p className='m-0 cursor-pointer' onClick={openPageHandler.bind(this, 'https://instagram.com/16ayurashmi?igshid=MzRlODBiNWFlZA==')}>
                                    @16ayurashmi
                            </p>
                        </div>
                   
                    </Col>
                   
                </Row>
            </Col> 
            </Row>
            {/* <ToastContainer /> */}
        </Container>
    )
}

export default Contact;