import { Col, Container, Row } from 'react-bootstrap';
import { dummy, rashmi } from '../../assets/images';
import './about-us.scss';

const AboutUs = () => {

    return(
        <Container fluid className="about" id="about-us">
            <Row className="header my-5">
                <h5>About Us</h5>
            </Row>
            {/* <Row>
                <Col xs={12}></Col>
            </Row> */}
            <Row className="about-founders">
                <Col xs={12}>
                    <div className="about-doctor">
                        <Row>
                            <Col xs={12} sm={6}>
                        <img src={rashmi} alt="Dr.Rashmi"/>
                        </Col>
                        <Col xs={12} sm={6}>
                        <p className="doctor-description">
                        Dr. Rashmi Bhatt is the face behind the establishment of Ayu Rashmi Ayurveda.
                        Reason behind the establishement was to provide exclusive range of Ayurveda treatment to the patients. 
                        Dr. Rashmi has completed her undergraduatation from Himalayiya Ayurveda university, dehradun, Uttrakhand and pursued  her post graduation from Sri Dharmasthala Manjunatheswara College of Ayurveda and Hospital, Hassan, Karnataka. 
                        She has a experience of 5 years in the field of Ayurveda. Dr. Bhatt offers an exclusive range of treatment for skin disorders (like psoriasis, vitiligo, eczema, Hyperpigmentation, etc.) 
                        Gynecological issues like PCOD, fibroids, dysmenorria, irregular periods etc., digestive disorders, obesity and many other ailments. 
                        The treatment will be planned individually for each patient after the detailed examination of the patient for his/her prakriti, age,strength, agni and dosha etc. 
                        </p>
                        </Col>
                        </Row>
                </div>
                </Col>
                <Col xs={12} className="mt-5">
                <div className="about-doctor">
                <Col xs={12} sm={6}>
                        <img src={dummy} alt="Dr.Pankaj"/>
                        </Col>
                        <Col xs={12} sm={6}>
                        <p className="doctor-description">
                        Dr. Pankaj Bhatt is also the face behind the establishment of Ayu Rashmi. 
                        He is also a renowned Ayurvedic consultant in dehradun. Dr. Pankaj completed his Undergraduation from Himalayiya university dehradun, Uttrakhand. 
                        After completing his undergraduation he started practicing Ayurveda. He has the expertise of 6 years in the field of Ayurveda. 
                        He offers an exclusive range of treatment for anorectal disorders, diabetes,joint disorders and many more. 
                        </p>
                    </Col>
                </div>
                </Col>

            </Row>
        </Container>
    )
}

export default AboutUs;