import { useEffect } from 'react';
import './App.scss';
import AboutUs from './components/about-us/about-us';
import Contact from './components/contact/contact';
import Header from './components/header/header';
import Home from './components/home/home';
import PrivacyPolicy from './components/privacy-policy/privacy-policy';
import Services from './components/services/services';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


const App = () => {

  useEffect(() => {
    initFirebase();
  }, [])

  const initFirebase = () => {

      // Your web app's Firebase configuration
      // For Firebase JS SDK v7.20.0 and later, measurementId is optional
      const firebaseConfig = {
        apiKey: "AIzaSyCb_BQcc3OGkmixIXp5KAqQBK4-awtlMPs",
        authDomain: "ayurashmi-72493.firebaseapp.com",
        projectId: "ayurashmi-72493",
        storageBucket: "ayurashmi-72493.appspot.com",
        messagingSenderId: "532914051738",
        appId: "1:532914051738:web:b0113ff7daba9c1e759d23",
        measurementId: "G-Y089SMBDXY"
      };

      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      getAnalytics(app);
  }

  return (
    <div className="ayu-rashmi-app">
        <Header/>
        <div className="main">
          {
            window.location.pathname==='/privacy-policy' ? <PrivacyPolicy/> :<>
             <Home/>
          <Services />
          <AboutUs />
          <Contact />
          </>
          }
         
        </div>
    </div>
  );
}

export default App;
