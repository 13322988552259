import { Col, Container, Row } from 'react-bootstrap';
import { servicesList } from '../../common/common.constant';
import './services.scss';

const Services = () => {

    return(
        <Container fluid className="services" id="services">
            <Row className="top-section">
                 <h1>Our Holistic Services</h1>
                 <p>
                 All of our individualized treatments are carefully designed to relieve your pain and help you live a happier, more comfortable life.
                Our perception of health focuses on our patient’s body's structure, physiological systems, diet and nutrition, and their emotional and psychological state. 
                 Check out our list of treatments and services below to learn more about what we can do to help you heal.
                 </p>
            </Row>
            <Row className="justify-content-center mx-1">
                {
                    servicesList.map((service, index) => (
                        <Col className="service-card" xs="12" sm="4" md="3" key={service.serviceName + index}>
                                <div className="service-name">
                                    <h5>{index === 2 ?service.serviceName  :service.serviceName.toLowerCase()}</h5>
                                </div>
                                <div className="image-container">
                                    <img src={service.image} alt={service.serviceName} />
                                </div>
                                <div className="service-description">
                                    <p>{service.description}</p>
                                </div>
                        </Col>
                    ))
                }
            
        </Row>
        <Row>
            <Col>
                <h2>
                    We offer a wide variety of additional treatments. Contact us today to learn more and schedule a session.
                </h2>
            </Col>
        </Row>
        </Container>
    )
}

export default Services;