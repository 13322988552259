import { arthritis, digestiveDisorder, migrain, panchkarma, pcod, skin, stress } from "../assets/images";

export const servicesList = [
    {
        serviceName: 'SKIN DISEASES',
        description: `Skin, the basic element that provides protection from external environment. 
        Glowing and  healthy skin expedite the personality and  beauty of a person.
        Diseases of skin account for a great deal of misery, suffering for both  physical and mental health of a person.`,
        image: skin,
    },
    {
        serviceName: 'MENTAL HEALTH(stress) AND AYURVEDA',
        description: `In this present scenario of pandemic every person is in distress and anxiety. 
        Various studies by American psychological association shows that 80% of people are facing this situation.
         Ayurveda is a holistic science that acknowledges the mind-body correlation. 
         Ayurvedic therapies will be a helping hand for this.`,
        image: stress,
    },
    {
        serviceName: 'PCOD',
        description: `PCOD is one of the most common hormonal issue in the reproductive age group. 
        Various studies shows that out of 5 womens suffers from this. 
        Can be well managed with proper diet and lifestyle modifications.`,
        image: pcod,
    },
    {
        serviceName: 'DIGESTIVE DISORDERS',
        description: `Ayurveda believes for proper digestion of the food digestive fire (Agni) is required, 
        any imbalance in this Agni is the root cause for all the digestive disorders.`,
        image: digestiveDisorder,
    },
    {
        serviceName: 'RHEUMATOLOGY AND ARTHRITIS',
        description: `Inflammation in the joints is named as arthritis.
         This term includes so many diseases related to joints and it's tissues. 
         Any deformity or Derrangement in the joint tissues and it's structure comes under the rheumatology. `,
        image: arthritis,
    },
    {
        serviceName: 'MIGRAINE',
        description: `Headache that causes throbbing pain or pulsatile sensation usually in half part of the head.
         Often happens with sensation of nausea, vomiting, sensitivity to light and sound etc. and remains for hours or days.`,
        image: migrain,
    },
    {
        serviceName: 'PANCHKARMA',
        description: `It is the most eminent branch of ayurveda.
         It is the method of cleansing the body from all the unwanted waste after lubricating it. 
         Retoring the youth with the help of most famous detoxification process of ayurveda. `,
        image: panchkarma,
    }
]