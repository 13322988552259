import { Container, Nav, Navbar } from 'react-bootstrap';
import { logo } from '../../assets/images';

import './header.scss';

const Header = () => {

    return (
        <Navbar collapseOnSelect expand="lg"  bg="dark" variant="dark" sticky="top">
    <Container fluid>
      <Navbar.Brand href="#home">
        <div className="header-banner-logo">
        <img
          alt=""
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{' '}
        <div className="header-text">
        Ayu Rashmi: offering hope and healing
      <p>Peaceful serenity awaits those who enter our gates.</p>
      </div>
      </div>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      </Navbar.Brand>
  <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end flex-grow-1">
    <Nav>
      <Nav.Link href="#home">Home</Nav.Link>
      <Nav.Link href="#services">Services</Nav.Link>
      <Nav.Link href="#about-us">About us</Nav.Link>
      <Nav.Link href="#contact">Contact</Nav.Link>
     
    </Nav>
    
  </Navbar.Collapse>
    </Container>
  </Navbar>
    )
}

export default Header